<script setup>

import { ref } from "vue"
import { useAccountStore } from "@/store/account"

import MemberAvatarNameWithTag from "@/components-uhas/members/MemberAvatarNameWithTag.vue"

const accountStore = useAccountStore()

const accounts = ref([])
const loading = ref(true)
const filter = ref(null)
const totalRecords = ref(0)

const clearFilter = () => {
  filter.value = null
  search()
}

const search = (e = {}) => {
  loading.value = true

  const page = (e.page || 0) + 1
  accountStore.index({ filter: filter.value, page }).then((res) => {
    accounts.value = res.data.accounts.data
    totalRecords.value = res.data.accounts.total

    loading.value = false
  })
}

search()

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Account</h5>
        <DataTable
          class="p-datatable-gridlines"
          dataKey="id"
          filterDisplay="row"
          :lazy="true"
          :loading="loading"
          :paginator="true"
          responsiveLayout="scroll"
          :rowHover="true"
          :rows="10"
          :totalRecords="totalRecords"
          :value="accounts"
          @page="search($event)"
        >

          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="Uhas UID" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
            </div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column field="type" header="Provider" />

          <Column field="name" header="Name">
            <template #body="{data}">
              <router-link :to="`/members/${data.member.id}`" target="_blank">
                <MemberAvatarNameWithTag :member="data.member" />
              </router-link>
            </template>
          </Column>

					<Column field="commission" header="Commission" />

					<Column field="lots" header="Lots" />

					<Column header="Updated">
						<template #body="{data}">
							{{ data.updated_at ? new Date(data.updated_at).toLocaleString() : '' }}
						</template>
					</Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>
