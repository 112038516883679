import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useAccountStore = defineStore('account', {
  actions: {
		index: ({ filter, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/accounts?page=${page}${ filter ? '&filter=' + filter : '' }`),
  }
})
